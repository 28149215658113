import React, { useState, useEffect } from "react";

import useConfig from "../utils/config";
import { useApiAddReservation } from "../services/apiAddReservation";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { blue, red } from '@mui/material/colors';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'; 
import { LoadingIcon } from "../components/Loading";
import Tooltip from '@mui/material/Tooltip';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';


import useFunctions  from '../utils/functions';

export default function CardMyReservation(props) {

    const cfg = useConfig();

    const api = useApiAddReservation();
    const {countDaysLeft, formatDateWithWeekday} = useFunctions();

    const [urvId, setUrvId] = useState(0);
    const [refUrv, setRefUrv] = useState(null);
    const [canDelete, setCanDelete] = useState(false);    
    const [isOutdated, setIsOutdated] = useState(false);    

    const [isWorking, setIsWorking] = useState(false);

    const handleRemoveClick = () => {
        setIsWorking(true);
        api.removeReservation(urvId)
        .then ((json) => {          
            if (json.returnCode===0) {   
                setIsWorking(false);   

                if (json.returnDataCount===1) {
                    setUrvId(0);     
                    setIsWorking(false);
                    if (props.deleteAction) props.deleteAction();                              
                }    
                //setMessage(null);
            } else {
                setIsWorking(false);
                //setMessage(json.returnMsg || 'Erreur suppression');
            }  
        });         
    };

    const data = props.data;
    const urv_far_data = props.data.urv_far_data || {};    

    useEffect(() => {
        if (data.urv_id>0) {
            setUrvId(data.urv_id);
            
            setCanDelete(countDaysLeft( new Date(), data.urv_date)>cfg.defaultValues.nbDaysCanDeleteUrv) ;
            setIsOutdated(countDaysLeft( new Date(), data.urv_date)<0) ;            
    
            let elements = [];

            if (typeof(urv_far_data.baseRef)==="string" &&  urv_far_data.baseRef.length>0) elements.push(urv_far_data.baseRef);
            elements.push(data.urv_date.replace(/-/g,'')) ;   
            elements.push(data.urv_id);
            setRefUrv(elements.join('-'));            
        }
    },[])

    return (
        urvId>0 
        ?    
        <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <CardHeader           
                sx={{ backgroundColor : "#DEDEDE", py :1.52}} 
                title={
                    <Typography gutterBottom component="h4" variant="h6" sx={{py:0,mb:0}}>{data.urv_far_lib}</Typography>
                }
                subheader={
                    <Box sx={{ fontSize: 12, display: 'flex', flexDirection:'row', py : 0.5, my :0}}>
                        Réf : {refUrv}
                    </Box>
                }  
                action={
                    <Box sx={{ display: 'flex', flexDirection:'column'}}>
                    {
                        canDelete 
                            ? (
                            isWorking 
                                ? 
                                <Box sx={{ mr:0, pt:2 }}><LoadingIcon/></Box>
                                :
                                <Tooltip title="Annuler la réservation" >
                                    <IconButton sx={{ px: 0, py:2, m:0 }}  onClick={handleRemoveClick} >
                                        <RemoveCircleIcon sx={{ typography: { sm: 'h4', xs: 'h4' }, color: red[500] }}/>
                                    </IconButton>
                                </Tooltip>                                
                            )    
                            : ""                                
                    }        
                    </Box>
                    
                }

            />
            <CardContent sx={{ backgroundColor: '#EFFFFF', px:1, py:1}}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <CardMedia component="img" sx={{ mr :3, width:{ xs:100, sm:150} }}
                        image={require("../assets/resources/" + urv_far_data.picture)} 
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography gutterBottom component="div" variant="body" sx={{py:0,mb:0}}>
                    {"Le " + formatDateWithWeekday(data.urv_date)}
                    <br />
                    {"de " + (data.urv_tis_data.start ?? '-') + " à " + (data.urv_tis_data.end ?? '-')}                
                    </Typography>    
                    {canDelete 
                        ?            
                        <Typography gutterBottom component="div" variant="body" sx={{fontSize: 11, fontWeight:500, color:blue[600], py:0,mb:0}}>
                            {
                                cfg.defaultValues.nbDaysCanDeleteUrv>0 
                                ?
                                'Annulation sans frais jusqu\'à ' + cfg.defaultValues.nbDaysCanDeleteUrv + ' jour(s) avant.'
                                :
                                ''
                            }
                        </Typography>
                        :
                        <Typography gutterBottom component="div" variant="body" sx={{fontSize: 11, fontWeight:700, color:red[600], py:0,mb:0}}>
                           {isOutdated ? "Réservation effectuée ": "Annulation impossible." }
                        </Typography>

                    }
                    { data.urv_data.opt &&  data.urv_data.opt.length>0
                        ? 
                        <>
                        <Typography gutterBottom component="div" variant="body" sx={{fontSize: "0.9em", py:0,mt:1, mb:0}}>
                            Vos options :
                        </Typography>
                        <ul style={{ 
                            marginTop: 0.5, 
                            marginBottom: 0.5, 
                            paddingLeft : 25,
                            fontSize: "0.9em"
                        }}>
                        {    
                            data.urv_data.opt.map((value, index) => (
                            <li key={index}>{value.lib} </li>
                        ))
                        }
                        </ul>                    
                        </>
                        : 
                        ""
                    }
                    </Box>
                </Box>
            </CardContent>
       </Card>
       :
       ""
    );
}