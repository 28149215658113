import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '../../utils/auth';
import { TitlePage } from "../../components/TitlePage";
import { LoadingIcon } from "../../components/Loading";
import {TextError , TextMessage} from '../../components/TextInformation';

import { useApiReservations } from "../../services/apiReservations";         

import ScrollButtonToTop from '../../components/ScrollButtonToTop'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
//import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';      
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'; 

import ConfirmDelete from '../../components/ConfirmDelete';
import useFunctions  from '../../utils/functions';

export function Reservations() {

    const {user} = useAuth();
    const api = useApiReservations();
  
    const {formatDate} = useFunctions();

    const [infoMessage, setInfoMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [activeTab, setActiveTab] = useState(0);
    const [isWorking, setIsWorking] = useState(false);    

    const [isLoadedA, setIsLoadedA] = useState(false);
    const [dataA, setDataA] = useState({})

    const [isLoadedH, setIsLoadedH] = useState(false);
    const [rowCountH, setRowCountH] = useState(0);
    const [dataH, setDataH] = useState({})

    // supprime une réservation -----------------------------------------------------------
    const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false);
    const [contentTextDeleteDialog, setContentTextDeleteDialog] = useState('');
    const [urvIdToDelete, setUrvIdToDelete] = useState(0);

    const handleRemoveClick = (e, row) => {
      e.stopPropagation();
      let txt = 'Voulez-vous vraiment supprimer la réservation de ' + row.urv_usr_firstname + ' ' + row.urv_usr_name;
      txt += ' pour un ' + row.urv_far_lib + ' ?';
      txt += "<br /><br />Date : " + formatDate(row.urv_date);
      txt += "<br />Horaire : " + row.urv_tis_lib;

      setUrvIdToDelete(row.urv_id);     
      setContentTextDeleteDialog(txt);
      setIsOpenConfirmDeleteDialog(true);
    };    

    const handleNoDelete = () => {      
      setUrvIdToDelete(0);     
      setIsOpenConfirmDeleteDialog(false);
      setContentTextDeleteDialog('');
    }
    const handleYesDelete = () => {
      setIsWorking(true);
      api.deleteData(urvIdToDelete)
      .then ((json) => {          
          setIsWorking(false);           
          if (json.returnCode===0) {              
            setUrvIdToDelete(0);     
            setIsOpenConfirmDeleteDialog(false);
            setContentTextDeleteDialog('');        
            if (json.returnDataCount===1) {
              // refresh
              setIsLoadedA(false);
            }    
            setErrorMessage(null);
          } else {
              setUrvIdToDelete(0);     
              setIsOpenConfirmDeleteDialog(false);
              setContentTextDeleteDialog('');        
              setErrorMessage(json.returnMsg || 'Erreur suppression');
          }  
      });         
    }
    //--------------------------------------------------------------

    const columnsA = [
      { field: 'urv_id', headerName: 'ID', width: 60 , headerClassName: 'app-theme--header'},
      { field: 'urv_date', headerName: 'Date', width: 100 , headerClassName: 'app-theme--header'
        , valueFormatter: (params) => {
          return formatDate(params.value);
        }
      },                              
      { field: 'urv_tis_lib', headerName: 'Horaire', type : 'string',  width: 100 , headerClassName: 'app-theme--header'},                                    
      { field: 'urv_far_lib', headerName: 'Bureau', type : 'string',  width: 150, headerClassName: 'app-theme--header' },                                          
      { field: 'urv_usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
      { field: 'urv_usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
      { field: 'urv_usr_email', headerName: 'Email / Login', width: 250 , headerClassName: 'app-theme--header'},
      { field: 'actions', disableColumnMenu: true, resizable: false, filterable: false, sortable: false, editable: false, groupable: false,headerName: 'Actions'
        , width: 100, headerAlign : 'center', align : 'center', 
        renderCell: (params) => {
          return (
            <Box>
            <IconButton sx={{ ml: 0, px: 0, py:2, my:0 }}  onClick={(e) => handleRemoveClick(e, params.row)} >
              <RemoveCircleIcon sx={{ typography: { sm: 'h4', xs: 'h4' }, color: red[500] }}/>
            </IconButton>    
            </Box>        
          );
      } }
    ];            

    const columnsH = [
      { field: 'urv_id', headerName: 'ID', width: 60, headerClassName: 'app-theme--header' },
      { field: 'urv_date', headerName: 'Date', width: 100, headerClassName: 'app-theme--header'
        , valueFormatter: (params) => {
          return formatDate(params.value);
        }
      },
      { field: 'urv_tis_lib', headerName: 'Horaire', type : 'string',  width: 100, headerClassName: 'app-theme--header' },
      { field: 'urv_far_lib', headerName: 'Bureau', type : 'string',  width: 150, headerClassName: 'app-theme--header' },
      { field: 'urv_usr_firstname', headerName: 'Prénom', width: 150, headerClassName: 'app-theme--header'},
      { field: 'urv_usr_name', headerName: 'Nom', width: 150, headerClassName: 'app-theme--header'},
      { field: 'urv_usr_email', headerName: 'Email / Login', width: 250, headerClassName: 'app-theme--header' }
    ];            

    //--------------------------------------------------------------    
    const handleChangeTab = (event, newValue) => {
      setActiveTab(newValue);
    };  

    const getRowId = (data) => {
        return data.urv_id;
    }

    useEffect(() => {      

      if (user && activeTab===0 && !isLoadedA  ) {
        setIsWorking(true);
        api.getData(0)
          .then ((json) => {          
            setIsWorking(false);
            if (json.returnCode===0) {        
              setDataA(json.returnData);
              setIsLoadedA(true);              
              setInfoMessage('');
            } else {
              setDataA({});
              setIsLoadedA(false);
              setInfoMessage(json.returnMsg || 'Aucune réservation');
            }  
          }
        );
      }

      if (user && activeTab===1 && !isLoadedH ) {
        setIsWorking(true);
        api.getData(1)
            .then ((json) => {  
              setIsWorking(false);        
              if (json.returnCode===0) {        
                setDataH(json.returnData);
                setRowCountH(json.returnTotalDataCount);
                setIsLoadedH(true);              
                setInfoMessage('');
              } else {
                setDataH({});
                setIsLoadedH(false);
                setInfoMessage(json.returnMsg || 'Aucune réservation');
              }  
            }); 
      }

    }, [user, activeTab, isLoadedA, isLoadedH]);

    return (        
        <Fragment>
          {/* pour la gestion de la suppression */}
          <ConfirmDelete open={isOpenConfirmDeleteDialog} contentText = {contentTextDeleteDialog} handleNo={handleNoDelete} handleYes={handleYesDelete}/>

          <TitlePage titlePage="Réservations" />  

          {isWorking && <Container align="center" sx={{ py : 5}}><LoadingIcon /></Container>}
          {infoMessage!=='' &&  <TextMessage>{infoMessage}</TextMessage>}
          {errorMessage!=='' &&  <TextError>{errorMessage}</TextError>}
          
          <Container sx={{ pt: 2, pb : 4 }} maxWidth="xl">
            
            {/* les tabs  */ }
            <Box sx={{ mb: 2, py :0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleChangeTab}>
                <Tab label="A venir" />
                <Tab label="Historique" />
              </Tabs>
            </Box>

            {(activeTab===0 && dataA && dataA.length>0) &&
              <DataGrid
                getRowId={getRowId}
                rows={dataA}
                columns={columnsA}                
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 100 },
                  },
                }}
                filterMode = 'client'
                pageSizeOptions={[100]}
              />
            }    
            {(activeTab===1 && dataH && dataH.length>0) &&
              <DataGrid
                getRowId={getRowId}
                rows={dataH}
                columns={columnsH}
                rowCount={rowCountH}
                paginationMode="server"
                onPaginationModelChange={(model, details) => {console.log(model, details);}}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[100]}
              />
            }    

          </Container>

          <ScrollButtonToTop />

        </Fragment>
    );    
};