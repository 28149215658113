import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '../utils/auth';
import { LoadingIcon } from "../components/Loading";
import { useApiMyPackages } from "../services/apiMyPackages";
import ScrollButtonToTop from '../components/ScrollButtonToTop';

import { TitlePage } from "../components/TitlePage";
import {TextError} from '../components/TextInformation';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';      

import CardMyPackage from "../components/CardMyPackage";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export function MyPackages() {

  const {user} = useAuth();
  const api = useApiMyPackages();

  const [isLoaded, setIsLoaded] = useState({tab0: false, tab1: false } );
  const [data, setData] = useState({data0 : {}, data1:{}})

  const [errorMessage, setErrorMessage] = useState("")

  const [activeTab, setActiveTab] = useState(0);
  const [isWorking, setIsWorking] = useState(false);        

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };  

  useEffect(() => {      
    if (user && !isLoaded["tab" + activeTab]) { 
      setIsWorking(true);
      api.getData(activeTab)
        .then ((json) => {          
          setIsWorking(false);
          let tempL = {...isLoaded};
          let tempD = {...data};
          tempL["tab" + activeTab]=true;
          if (json.returnCode===0) {     
            tempD["data" + activeTab] = json.returnData;   
            setErrorMessage("");
          } else {            
            tempD["data" + activeTab] = {};   
            setErrorMessage(json.returnMsg);
        
          }  
          setData(tempD);       
          setIsLoaded(tempL);                 
        }); 
    }
  }, [user, activeTab, isLoaded]);

  const getData = (tabId) => {
    let key = "data" + tabId;
    return (data[key] && data[key].length>0) ? data[key] : null;
  }

  return (
    <Fragment>
      <TitlePage titlePage="Mes abonnements" showAddButton="right" tooltipAddButton="Ajouter un abonnement" routeAddButton="/AddPackage"/>

      {isWorking && <Container align="center" sx={{ py : 5}}><LoadingIcon /></Container>}
      {errorMessage!=='' &&  <TextError>{errorMessage}</TextError>}

      <Container sx={{ pt: 2, pb : 4 }} maxWidth="xl">

        {/* les tabs  */ }
        <Box sx={{ mb: 2, py :0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleChangeTab}>
            <Tab label="En cours" />
            <Tab label="Historique" />
          </Tabs>
        </Box>
        {/* les datas */}
        {getData(activeTab) && 
          <Grid container spacing={2}>                            {
            getData(activeTab).map((value, index) => (                                      
              <Grid item key={value.upk_id} xs={12} sm={6} >
                <CardMyPackage data={value} />
              </Grid>
            ))
            }
          </Grid>                    
        }
      </Container>
  
      <ScrollButtonToTop />
    </Fragment>
  );
};