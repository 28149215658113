
import React, {Fragment} from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }   from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { blue, red } from '@mui/material/colors';

import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';

import useFunctions  from '../utils/functions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#888888",
    color: theme.palette.common.white,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function ListAddReservation(props) {
    
  const data = props.data || [];
  const {formatDateWithWeekday} = useFunctions();
   
  return (
    <>
    <TableContainer component={Paper} sx={{
      width: {
        xs : 350,
        sm : 500
      }
    }}>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell ></StyledTableCell>
              <StyledTableCell align="center">Jour</StyledTableCell>
              <StyledTableCell align="center">Horaire</StyledTableCell>
              <StyledTableCell sx={{ width:20}} align="right">Coût</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody >
          {data.map((row) => (
            <StyledTableRow
              key={row.urv_far_id +'-' + row.urv_tis_id}
              sx={{ border: 0, color:'red' }}
            >
              <StyledTableCell sx={{ px:1, width:20}}>{row.urv_status===0 ? <CheckIcon sx={{ py:0, my:0, color : blue[500] }}/> : <BlockIcon sx={{py:0, my:0,color:red[500]}} />}</StyledTableCell>
              <StyledTableCell align="center">{formatDateWithWeekday(row.urv_date)}</StyledTableCell>
              <StyledTableCell align="center">{row.urv_data.tisLib}</StyledTableCell>
              <StyledTableCell align="right">{row.urv_status===0 ? row.urv_data.nbPassCost : ''}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </>
  );
}

