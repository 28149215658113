import React, { useState, useEffect } from "react";

import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { red, blue } from '@mui/material/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'; 
import CheckIcon from '@mui/icons-material/Check'; 

import useFunctions  from '../utils/functions';
import { useBasket }  from '../utils/basket';
import { LoadingIcon } from "../components/Loading";
import { HtmlSpan } from "../components/HtmlSpan"
import { useApiAddPackage } from "../services/apiAddPackage";

const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(
        ({ theme, expand }) => ({
            transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            })
        })
    );

export default function CardBasketPackage(props) {

    const api = useApiAddPackage();

    const {formatEuro} = useFunctions();
    const basket = useBasket();

    const [isWorking, setIsWorking] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [ubkId, setUbkId] = useState(0);

    const data = props.data;
    const ubkData = props.data.ubk_data || {};
    const upkData = ubkData && (ubkData.upkData || {});

    const getLibPrice = ()  => {        
        let value = upkData.pri_data.price;
        if (isNaN(value) ||value===0) {
            return "nous consulter";
        } else {

            if (ubkData.upkOptionsSelected && ubkData.upkOptionsSelected.length>0) {
                ubkData.upkOptionsSelected.forEach(opt => {
                    if (upkData.pri_data.optionsPrice && upkData.pri_data.optionsPrice[opt]) {
                        value = value + upkData.pri_data.optionsPrice[opt];
                    } 
                })
            }
    
            // le prix est total (12 mois)            
            return formatEuro(value/12, 2) + '/mois (' + formatEuro(value,0) + '/an)';
        }
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleRemoveClick = () => {
        setIsWorking(true);
        api.removeFromBasket(ubkId)
        .then ((json) => {          
            if (json.returnCode===0) {   
            setIsWorking(false);   
            //console.log(json);
            if (json.returnDataCount===1) {
                setUbkId(0);     
                setIsWorking(false);                              
                basket.removePackage("p" + upkData.pri_id); 
            }    
                //setMessage(null);
            } else {
                setIsWorking(false);
                //setMessage(json.returnMsg || 'Erreur ajout');
            }  
        }); 
        
    };

    const handleConfirmClick = () => {
        // api remove
        setIsWorking(true);
        api.confirmFromBasket(upkData, ubkData.upkOptionsSelected )
        .then ((json) => {          
            if (json.returnCode===0) {   
                setIsWorking(false);   
                if (json.returnDataCount===1) {
                    setUbkId(0);     
                    setIsWorking(false);                              
                    handleRemoveClick();
                }    
            } else {
                setIsWorking(false);
                //setMessage(json.returnMsg || 'Erreur ajout');
            }  
        }); 
    }

    // on repère quel package est déjà dans le basket    
    useEffect(() => {
        if (data.ubk_id>0) {
            setUbkId(data.ubk_id);
        }
    },[])
    
    return (
        ubkId>0 
            ?
            <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <CardHeader           
                    sx={{ backgroundColor : "#DEDEDE", py :1.52, minHeight:60}} 
                    avatar={
                        <Badge 
                        sx={{ "& .MuiBadge-badge": { fontSize: 10, color : 'white', backgroundColor: blue[300], top:'110%', right : '50%'} }} 
                        variant="string" 
                        badgeContent={upkData.pri_data.typePass==="d"? "jours" : "heures"} 
                        overlap="circular" 
                        >
                            <Avatar sx={{ bgcolor: blue[300], mt: -1.5, fontSize:16, width:50, height:50}} aria-label="recipe">
                            {upkData.pri_data.nbPass}
                            </Avatar>                    
                        </Badge>
                    }                
                    title={
                        <Typography gutterBottom component="h4" variant="h7" sx={{py:0,mb:0}}>{upkData.pri_lib}</Typography>
                    }
                    subheader={
                        <Box sx={{ display: 'flex', flexDirection:'column', py : 0, my :0}}>
                            Tarif : {getLibPrice()}
                        </Box>
                    }  
                    action={
                        <Box sx={{ display: 'flex', flexDirection:'column'}}>
                            {isWorking ? <Box sx={{ mr:0, pt:2 }}><LoadingIcon/></Box>
                                :
                                <Tooltip title="Supprimer du panier" >
                                    <IconButton sx={{ px: 0, py:2, m:0 }}  onClick={handleRemoveClick} >
                                        <RemoveCircleIcon sx={{ typography: { sm: 'h4', xs: 'h4' }, color: red[500] }}/>
                                    </IconButton>
                                </Tooltip>                                
                            }    
                        </Box>
                    }
                />
                <CardContent  sx={{ my:0, py:0, pt: 1,px:2 }} >
                    <Typography paragraph sx={{ fontSize: "1em", mx:0, px : 0 }}>
                            {upkData.pri_data.description}
                    </Typography>

                { // si options
                ubkData.upkOptionsSelected && ubkData.upkOptionsSelected.length>0 && upkData.pri_data.optionsAllowed && upkData.pri_data.optionsAllowed.length>0
                && 
                    <>
                    <Typography paragraph sx={{ fontSize: "1em", mx:0, px : 0, py :0, my : 0 }}>
                        Option(s) sélectionnée(s) :
                    </Typography>
                    <ul style={{ marginTop: 0.5 }}>
                    {    
                    ubkData.upkOptionsSelected.map((value, index) => (
                        <li key={index}>{upkData.pri_data.optionsAllowedLib[value]} </li>
                    ))
                    }
                    </ul>
                    </>
                }
                </CardContent>                    

                <CardActions sx={{ my:0, py:0, px:2, display:'flex', flexDirection:'row' }} disableSpacing>
                    <Button onClick={handleConfirmClick} ><CheckIcon fontSize="small" sx={{ pr:1}}/> Valider la demande</Button>
                    <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                    >                    
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ backgroundColor: '#EFFFFF'}}>
                    <HtmlSpan text={upkData.pri_data.description2 ||""}/>
                    </CardContent>
                </Collapse>
            </Card>
            : 
            ""
    );
}